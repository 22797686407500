@import "_mixin";

.mdc-button {
    @include font-family;

    font-size: 14px !important;
    font-weight: 700 !important;
}

.module-option.mdc-button {
    justify-content: start !important;
}
