.mdc-list-item__primary-text {
    font-size: 14px !important;
}

.mat-mdc-menu-panel {
    border-radius: 10px !important;
}

.mdc-menu-surface {
    border-radius: 10px !important;
}

.mat-datepicker-content {
    padding: 10px !important;
    border-radius: 10px !important;

    &.email {
        font-size: 12px;
    }
}