@import "_mixin";
@import "breakpoints";

@mixin max-width-mat-field {
  max-width: 180px !important;
}

.ng-tns-c33-2 {
  @include font-family;
}

.mdc-text-field--filled {
  border: 1px solid #f8f8fb;
  border-radius: 12px !important;
  background: #f2f6f9 !important;
  padding-bottom: 5px !important;
  color: #000 !important;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
}

.mat-form-field-underline {
  display: none !important;
}

.mdc-line-ripple {
  border: none !important;
}

.mdc-line-ripple::before,
.mdc-line-ripple::after {
  display: none !important;
}

.mat-mdc-form-field-infix {
  min-height: 12px !important;
  padding-top: 12px !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 300;
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: translateY(0px) !important;
}

.mat-mdc-select-value {
  @include font-family;

  color: #000 !important;
  font-weight: 300;
  font-size: 16px !important;
}

.datepicker {
  .mat-mdc-form-field-infix {
    border-bottom: none;
  }
}

.select {
  .mat-mdc-form-field-infix {
    border-bottom: none;
    color: #000 !important;
    font-weight: 300;
  }
}

.mdc-list-item__primary-text {
  @include font-family;
}

.mat-mdc-form-field-bottom-align {
  display: none !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field {
  height: 48px !important;
}

.mat-mdc-input-element {
  text-align: start !important;
}

.mat-datepicker-toggle-default-icon {
  color: #787a7d !important;
  margin-top: 10px !important;
}

.mat-mdc-select-placeholder {
  color: #000 !important;
  font-weight: 300;
}

.select-datepicker-left-radius {
  .mdc-text-field--filled {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.select-datepicker-right-radius {
  .mdc-text-field--filled {
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.mat-mdc-select-arrow svg {
  fill: #000 !important;
}
