@import "./assets/styles/main";
@import "_palette";
@import "_mixin";

html,
body {
  height: 100%;
  background: linear-gradient(rgba(177, 200, 252, 1), rgba(217, 217, 217, 0));
  background-repeat: no-repeat;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: $secondary-300 !important;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $secondary-400 !important;
}

body {
  @include font-family;
  margin: 0;
}
