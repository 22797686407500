.master-card-back {
  .card-chip {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    background-color: #2B2F3A;
  }

  .card-data {
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    .cvv {
      display: flex;
      align-items: center;

      p {
        margin: 0;
        color: #2B2F3A;
        font-size: 11px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border-right: 2px solid;
        padding-right: 5px;
        height: 22px;
        display: flex;
        align-items: center;
      }
    }

    .card-data-info {
      width: 100px;
      height: 27px;
      background-color: #2B2F3A;
    }
  }
}
