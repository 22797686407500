@import "_mixin";
@import "_palette";

.base-table {
    border-radius: 10px !important;

    .mat-mdc-table {
        border-radius: 10px !important;

        &.mat-mdc-cell {
            width: 150px !important
        }
    }

    .mat-sort-header-container {
        display: flex !important;
        justify-content: center !important;
    }

    .mat-mdc-table th:first-child {
        border-top-left-radius: 10px !important;
    }

    .mat-mdc-table th:last-child {
        border-top-right-radius: 10px !important;
    }

    .mat-sort-header-arrow {
        color: white !important;
    }

    .mat-mdc-header-cell {
        @include font-family;

        font-size: 12px;
        line-height: 19px;
        font-weight: 700 !important;
        background-color: $background-blue !important;
        color: white !important;
        text-align: center !important;
    }

    .mat-mdc-cell {
        @include font-family;

        font-weight: 500;
        font-size: 12.5px;
        line-height: 16px;
        color: #7B8086;
        text-align: center !important;
    }

    .mat-mdc-card-content {
        padding: 0 !important;
    }

    .mat-sort-header-content {
        width: 115px !important;
        display: flex; 
        justify-content: center;
    }
}