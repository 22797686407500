.default-card-front {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .card-chip {
    display: flex;
    align-items: center;
    gap: 5px;

    img.arrow {
      width: 5px;
    }

    img.card-chip-img {
      width: 26px;
    }
  }

  .card-data {
    padding: 0 4px;
    p {
      margin: 0;
      color: #fff;
      font-size: 11px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .card-data-info {
      display: flex;
      justify-content: space-between;
    }
  }
}
