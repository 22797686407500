@import "breakpoints";

.amex-card-front {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .card-chip {
    display: flex;
    align-items: center;
    gap: 5px;

    img.arrow {
      width: 5px;
    }

    img.card-chip-img {
      width: 26px;
    }
  }

  .card-data {
    padding: 0 4px;
    p {
      margin: 0;
      color: #fff;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .card-data-info {
      display: flex;
      justify-content: flex-end;
      position: relative;

      img {
        position: absolute;
        bottom: 5px;
        width: 40px;

        @include xs {
          width: 30px;
        }

        @include xxs {
          width: 25px;
        }
      }
    }
  }
}
