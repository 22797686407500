$xxs-min: 320px;
$xxs-max: 420px;
$xs-max: 600px;
$sm-min: 601px;
$sm-max: 899px;
$md-min: 900px;
$md-max: 1280px;
$lg-min: 1366px;
$lg-max: 1919px;
$xl-min: 1920px;
$header-breakpoint: 1100px;

//lt menor o igual que sm

// Usage:
// .example {
//   width: 1000px;
//   @include ltesm {
//     width: 500px;
//   }
// }


@mixin header-breakpoint-responsive {
  @media (max-width: #{$header-breakpoint}) {
    @content;
  }
}

@mixin header-breakpoint-desktop {
  @media (min-width: #{$header-breakpoint}) {
    @content;
  }
}

@mixin xxs {
  @media (max-width: #{$xxs-max}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$xs-max}) {
    @content;
  }
}

@mixin ltesm {
  @media (max-width: #{$sm-max}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$sm-min}) and (max-width: #{$sm-max}) {
    @content;
  }
}

@mixin smlg {
  @media (min-width: #{$sm-min}) and (max-width: #{$lg-max}) {
    @content;
  }
}

@mixin gtesm {
  @media (min-width: #{$sm-min}) {
    @content;
  }
}

@mixin ltemd {
  @media (max-width: #{$md-max}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$md-min}) and (max-width: #{$md-max}) {
    @content;
  }
}

@mixin gtemd {
  @media (min-width: #{$md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$lg-min}) and (max-width: #{$lg-max}) {
    @content;
  }
}

@mixin ltelg {
  @media (max-width: #{$lg-max}) {
    @content;
  }
}

@mixin gtelg {
  @media (min-width: #{$lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl-min}) {
    @content;
  }
}

@media (max-width: #{$xs-max}) {
}

@media (min-width: #{$sm-min}) and (max-width: #{$sm-max}) {
}

@media (min-width: #{$md-min}) and (max-width: #{$md-max}) {
}

@media (min-width: #{$lg-min}) and (max-width: #{$lg-max}) {
}

@media (min-width: #{$xl-min}) {
}
