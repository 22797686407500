@import "breakpoints";

@mixin filters {
  display: flex;
  align-items: center;
  gap: 10px;
}

@mixin font-family {
  font-family: Poppins, "Helvetica Neue", sans-serif !important;
}

@mixin cards-heigth {
  min-height: 470px;
  height: 100%;

  @include xs {
    min-height: 580px;
  }
}
